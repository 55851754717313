window.$ = window.jQuery = require('jquery');
import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'

import VAnimateCss from 'v-animate-css';
Vue.use(VAnimateCss);

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/js/bootstrap.js'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import PrimeVue from 'primevue/config';
Vue.use(PrimeVue, { ripple: true });

import 'primevue/resources/themes/tailwind-light/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

import moment from 'moment'
import 'moment/locale/es';
moment.locale('es');
Vue.prototype.moment = moment;

import Skeleton from 'vue-loading-skeleton';
import "vue-loading-skeleton/dist/style.css"
Vue.use(Skeleton)

import * as config from "./config";
Vue.prototype.$cg = config;

import AOS from 'aos'
import 'aos/dist/aos.css'

import VueToastr2 from 'vue-toastr-2'
import 'vue-toastr-2/dist/vue-toastr-2.min.css'
window.toastr = require('toastr')
Vue.use(VueToastr2)

toastr.options = {
    "closeButton": false,
    "debug": false,
    "newestOnTop": false,
    "progressBar": false,
    "positionClass": "toast-top-left",
    "preventDuplicates": false,
    "onclick": null,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
}

import VeeValidate from 'vee-validate';
import { Validator } from "vee-validate";
import es from "vee-validate/dist/locale/es.js";
import en from "vee-validate/dist/locale/en.js";
Vue.use(VeeValidate);

console.log("- Idioma del navegador - - ", navigator.language);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);
import { translations } from "./i18n/translations.js";

const messages = translations;

const storedLanguage = localStorage.getItem("appLanguage");
let locale = storedLanguage || "es"; // Por defecto "es" si no hay configuración previa

if (!storedLanguage) {
    const browserLanguage = navigator.language.toLowerCase();
    const generalLanguage = browserLanguage.substr(0, 2);

    if (generalLanguage === "en") {
        locale = "en"; // Inglés
    } else if (generalLanguage === "es") {
        // Mapeo de variantes específicas
        switch (browserLanguage) {
            case "es-es":
                locale = "es"; // España
                break;
            case "es-co":
                locale = "co"; // Colombia
                break;
            case "es-mx":
                locale = "mx"; // México (si lo tienes implementado)
                break;
            case "es-ar":
                locale = "ar"; // Argentina (si lo tienes implementado)
                break;
            // Agrega más variantes según tus necesidades
            default:
                locale = "es"; // Por defecto español genérico (España) en lugar de Colombia
                break;
        }
    } else {
        locale = "en"; // Si no se reconoce, usar inglés
    }

    localStorage.setItem('appLanguage', locale);
}

// Configurar idioma en VeeValidate
Validator.localize(locale, locale === "en" ? en : es);

const i18n = new VueI18n({
    locale,
    messages
});

Vue.prototype.$language = i18n;

import * as fn from "./config/funtionsglobal";
Vue.prototype.$funtion = fn;

Vue.config.productionTip = false

new Vue({
    i18n,
    router,
    render: h => h(App),
    mounted() {
        AOS.init();
    },
}).$mount('#app')
