<template>
  <div class="Content pm100">
    <div
      id="whatsappchat"
      class="lg"
    >
      <a
        :href="'https://api.whatsapp.com/send?phone=+34613845715&text=' + $t('home.p114')"
        target="_blank"
        class="whatsappLink"
      >
        <img
          class="whatsappimage"
          alt="WhatsApp Chat"
        />
        <span class="whatsapptext lg">
          <!-- <img class="whatsapp-ico" alt="WhatsApp Chat" /> -->
          <span>{{ $t("home.p63") }}</span>
        </span>
      </a>
    </div>

    <!-- Row1 -->
    <bloque-1 />

    <!-- Row2  -->
    <!-- End Edit  -->

    <div
      class="row justify-content-center CuerpoDos margi"
      style="
        background-color: white;
        padding-top: 100px;
        margin-top: 200px;
        padding-top: 142px !important;
        border-top: 3px solid #e6e6e6;
      "
    >
      <div
        class="col-12 col-lg-8 displayBox"
        data-aos="fade-in"
      >
        <div class="titulos">
          <div
            class="title-row2"
            style="
              font-family: 'Worldwise Sans' !important;
              font-weight: bold;
              font-style: normal;
              font-size: 70px;
              font-weight: 600;
              line-height: 65px;
              word-wrap: initial;
              word-break: keep-all;
              color: rgba(0, 0, 0, 1);
              font-weight: inherit;
            "
          >
            {{ $t("home.p11") }}
          </div>
          <div class="otro-texto">{{ $t("home.p12") }}</div>
        </div>

        <div
          class="contenido"
          style="margin-top: 65px"
        >
          <div class="container">
            <div class="row">
              <div
                data-aos="fade-left"
                class="col-lg-3"
                data-wow-delay=".2s"
                style="
                  visibility: visible;
                  animation-delay: 0.2s;
                  animation-name: fadeInUp;
                "
              >
                <div class="single-feature-44 mb-55">
                  <div class="feature-icon-2">
                    <img
                      src="/marca/iconos/apartamento.png"
                      alt=""
                      class=""
                      style="width: 45px"
                    />
                  </div>
                  <div class="feature-content-2">
                    <h4 class="feature-content-title-2">
                      {{ $t("home.p56") }}
                    </h4>
                  </div>
                </div>
              </div>

              <div
                data-aos="fade-left"
                class="col-lg-3"
                data-wow-delay=".2s"
                style="
                  visibility: visible;
                  animation-delay: 0.2s;
                  animation-name: fadeInUp;
                "
              >
                <div class="single-feature-44 mb-55">
                  <div class="feature-icon-2">
                    <img
                      src="/marca/iconos/villa.png"
                      alt=""
                      class=""
                      style="width: 45px"
                    />
                  </div>
                  <div class="feature-content-2">
                    <h4 class="feature-content-title-2">
                      {{ $t("home.p57") }}
                    </h4>
                  </div>
                </div>
              </div>

              <div
                data-aos="fade-up"
                class="col-lg-3"
                data-wow-delay=".2s"
                style="
                  visibility: visible;
                  animation-delay: 0.2s;
                  animation-name: fadeInUp;
                "
              >
                <div class="single-feature-44 mb-55">
                  <div class="feature-icon-2">
                    <img
                      src="/marca/iconos/hotel.png"
                      alt=""
                      class=""
                      style="width: 45px"
                    />
                  </div>
                  <div class="feature-content-2">
                    <h4 class="feature-content-title-2">
                      {{ $t("home.p58") }}
                    </h4>
                  </div>
                </div>
              </div>

              <div
                data-aos="fade-right"
                class="col-lg-3"
                data-wow-delay=".2s"
                style="
                  visibility: visible;
                  animation-delay: 0.2s;
                  animation-name: fadeInUp;
                "
              >
                <div class="single-feature-44 mb-55">
                  <div class="feature-icon-2">
                    <img
                      src="/marca/iconos/camping.png"
                      alt=""
                      class=""
                      style="width: 45px"
                    />
                  </div>
                  <div class="feature-content-2">
                    <h4 class="feature-content-title-2">
                      {{ $t("home.p59") }}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Bloque3 />
    <!-- ROW 3  -->
    <div
      class="p100 row justify-content-center CuerpoTres"
      style="background: white"
    >
     

      <div class="col-12 col-lg-8 pt-4">
        <div
          class="titulos"
          data-aos="fade-in"
        >
          <div
            class="title-row2"
            style="
              font-family: 'Worldwise Sans' !important;
              font-weight: bold;
              font-style: normal;
              font-weight: 600;
              line-height: 65px;
              word-wrap: initial;
              word-break: keep-all;
              color: rgba(0, 0, 0, 1);
              font-weight: inherit;
            "
          >
            {{ $t("home.p16") }}
            <span
              style="
                color: #eece22;
                font-family: 'Worldwise Sans' !important;
                font-weight: bold;
                font-style: normal;
                font-weight: 600;
                line-height: 65px;
                word-wrap: initial;
                word-break: keep-all;
                font-weight: inherit;
              "
            >
              {{ $t("home.p17") }}</span
            >
          </div>

          <div
            class="TextSubtitle"
            style="font-size: 23px; margin-top: 10px"
          >
            {{ $t("home.p18") }}
          </div>
          <div
            class="TextSubtitle"
            style="font-size: 23px; margin-top: 10px"
          >
            {{ $t("home.p19") }}
          </div>

          <div
            class="title2 mt-5"
            style="
              font-style: normal;
              font-size: 45px;
              font-weight: 600;
              line-height: 57px;
              word-wrap: initial;
              word-break: keep-all;
              color: #0e0f0c;
            "
          >
            {{ $t("home.p20") }}
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-10 mt-4">
        <div class="container">
          <div class="row">
            <div
              data-aos="fade-left"
              class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay=".2s"
              style="
                visibility: visible;
                animation-delay: 0.2s;
                animation-name: fadeInUp;
              "
            >
              <div class="single-feature-2 mb-55">
                <div class="feature-icon-2">
                  <img
                    src="/codigo-qr.png"
                    alt=""
                    class=""
                    style="width: 75px"
                  />
                </div>
                <div class="feature-content-2">
                  <h4 class="feature-content-title-2">{{ $t("home.p21") }}</h4>
                  <p class="feature-content-desc-2">
                    {{ $t("home.p22") }}
                  </p>
                </div>
              </div>
            </div>

            <div
              data-aos="fade-up"
              class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay=".2s"
              style="
                visibility: visible;
                animation-delay: 0.2s;
                animation-name: fadeInUp;
              "
            >
              <div class="single-feature-2 mb-55">
                <div class="feature-icon-2">
                  <img
                    src="/identidad.svg"
                    alt=""
                    class=""
                    style="width: 75px"
                  />
                </div>
                <div class="feature-content-2">
                  <h4 class="feature-content-title-2">{{ $t("home.p23") }}</h4>
                  <p class="feature-content-desc-2">
                    {{ $t("home.p24") }}
                  </p>
                </div>
              </div>
            </div>

            <div
              data-aos="fade-right"
              class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay=".2s"
              style="
                visibility: visible;
                animation-delay: 0.2s;
                animation-name: fadeInUp;
              "
            >
              <div class="single-feature-2 mb-55">
                <div class="feature-icon-2">
                  <img
                    src="/firma-digital.png"
                    alt=""
                    class=""
                    style="width: 75px"
                  />
                </div>
                <div class="feature-content-2">
                  <h4 class="feature-content-title-2">
                    {{ $t("home.p25") }}
                  </h4>
                  <p class="feature-content-desc-2">
                    {{ $t("home.p26") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- ROW 4  -->
    <div
      class="p100 row justify-content-center CuerpoTres"
      style="background: white"
    >

      <div
        data-aos="fade-left"
        class="p100 col-11 col-lg-5 pt-4"
        style="text-align: left"
      >
        <!-- <img src="/line-section.png" alt=""> -->
        <div
          class="titletres"
          style="
            font-style: normal;
            font-size: 45px;
            font-weight: 600;
            line-height: 57px;
            overflow-wrap: initial;
            word-break: keep-all;
            color: rgb(0, 0, 0);
            width: 100%;
          "
        >
          <!-- MENSAJE DE BIENVENIDA AUTOMÁTICO, VÍA WHATSAPP -->
          {{ $t("home.p27") }}
          <img
            src="/whatsapp.png"
            alt=""
            style="width: 40px"
          />
        </div>
        <div
          class="otro-texto"
          style="
            font-size: 20px;
            font-weight: 400;
            line-height: 28px;
            color: #454745;
          "
        >
          <hr />
          {{ $t("home.p28") }}
        </div>
        <br />
        <a href="https://app.wacheckin.com/register">
          <button
            class="btn"
            :style="{ background: $cg.color.colorOne }"
            style="
              color: white;
              border-radius: 10px;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              box-shadow: #1f962382 0px 10px 25px;
              transition: color, background-color 0.15s ease-in-out;
              padding: 11px 24px;
            "
          >
            {{ $t("home.p29") }}
          </button>
        </a>
      </div>

      <div
        class="col-12 col-lg-4 lg"
        data-aos="fade-right"
        style="height: 660px; margin-bottom: 73px"
      >
        <div
          class="bloqueGif"
          style="
            background-image: url('/gitcinco.gif');
            height: 100%;
            width: 299px;
            background-size: contain;
            background-repeat: no-repeat;
            margin: auto;
            border-radius: 10px;
            margin-left: 68px;
          "
        ></div>
       
      </div>
    </div>

    <!-- ROW 5  -->
    <div
      class="row justify-content-center CuerpoTresssss"
      style="background: white"
    >
      <div
        class="col-5 lg"
        data-aos="fade-right"
      >
        <img
          src="/ventas.jpg"
          class="img-fluid"
          alt=""
          v-animate-css="'zoomIn'"
        />
      </div>

      <div
        data-aos="fade-left"
        class="col-11 col-lg-5 pt-4"
        style="text-align: left; padding-top: 20px !important"
      >
        <div
          class="titletres"
          style="
            font-style: normal;
            font-size: 45px;
            font-weight: 600;
            line-height: 57px;
            overflow-wrap: initial;
            word-break: keep-all;
            color: rgb(0, 0, 0);
            width: 100%;
          "
        >
          <!-- MENSAJE DE BIENVENIDA AUTOMÁTICO, VÍA WHATSAPP -->
          {{ $t("home.p30") }}
          <img
            src="/whatsapp.png"
            alt=""
            style="width: 40px"
          />
        </div>
        <div
          class="otro-texto"
          style="
            font-size: 20px;
            font-weight: 400;
            line-height: 28px;
            color: #454745;
          "
        >
          <hr />
          {{ $t("home.p31") }}
        </div>
        <br />
      </div>

      <div
        class="col-12 xs"
        data-aos="fade-right"
      >
        <img
          src="/ventas.jpg"
          class="img-fluid"
          alt=""
          v-animate-css="'zoomIn'"
        />
      </div>
    </div>

    <!-- Nuevo bloque recordatorios  -->
    <!-- ROW 5  -->
    <div
      class="row justify-content-center CuerpoNuevo"
      style="background: white; padding-top: 150px"
    >
      <!-- <div class="col-10">
        <img src="/viajero1.jpg" alt="" class="img-fluid" style="border-radius:30px">
       
    </div> -->

      <div
        data-aos="fade-left"
        class="col-11 col-lg-5 pt-4"
        style="text-align: left; padding-top: 20px !important"
      >
        <!-- <img src="/line-section.png" alt=""> -->
        <div
          class="titletres"
          style="
            font-style: normal;
            font-size: 45px;
            font-weight: 600;
            line-height: 57px;
            overflow-wrap: initial;
            word-break: keep-all;
            color: rgb(0, 0, 0);
            width: 100%;
          "
        >
          <!-- MENSAJE DE BIENVENIDA AUTOMÁTICO, VÍA WHATSAPP -->
          {{ $t("home.p60") }}
          <!-- <img src="/whatsapp.png" alt="" style="width: 40px" /> -->
        </div>
        <div
          class="otro-texto"
          style="
            font-size: 20px;
            font-weight: 400;
            line-height: 28px;
            color: #454745;
          "
        >
          <hr />
          {{ $t("home.p61") }}
          <br />
          <br />
          {{ $t("home.p62") }}
        </div>
        <br />
      </div>

      <div
        class="col-5 lg"
        data-aos="fade-right"
      >
        <img
          src="/alarmas.svg"
          class="img-fluid"
          alt=""
          v-animate-css="'zoomIn'"
        />
      </div>

      <div
        class="col-12 xs"
        data-aos="fade-left"
      >
        <img
          src="/alarmas.svg"
          class="img-fluid"
          alt=""
          v-animate-css="'zoomIn'"
        />
      </div>
    </div>
    <!-- Nuevo bloque recordatorios end  -->

    <!-- ROW 5  -->
    <div
      class="p200 pm100 row justify-content-center CuerpoTres"
      style="background: white"
    >
      <!-- <div class="col-10">
        <img src="/viajero1.jpg" alt="" class="img-fluid" style="border-radius:30px">
       
    </div> -->

      <div
        data-aos="fade-left"
        class="col-11 col-lg-5 pt-4"
        style="text-align: left; padding-top: 20px !important"
      >
        <!-- <img src="/line-section.png" alt=""> -->
        <div
          class="titletres"
          style="
            font-style: normal;
            font-size: 45px;
            font-weight: 600;
            line-height: 57px;
            overflow-wrap: initial;
            word-break: keep-all;
            color: rgb(0, 0, 0);
            width: 100%;
          "
        >
          <!-- MENSAJE DE BIENVENIDA AUTOMÁTICO, VÍA WHATSAPP -->
          <!-- Envío automático a la policía -->
          {{ $t("home.p33") }}
        </div>
        <div
          class="otro-texto"
          style="
            font-size: 20px;
            font-weight: 400;
            line-height: 28px;
            color: #454745;
          "
        >
          <hr />
          {{ $t("home.p34") }}
        </div>
        <br />
      </div>

      <div
        class="col-12 col-lg-5"
        data-aos="fade-right"
      >
        <img
          src="/seguridad1.webp"
          class="img-fluid"
          alt=""
          style="
            border-radius: 22px;
            box-shadow: 0px 7px 11px 8px #eee;
            height: 80%;
          "
          v-animate-css="'zoomIn'"
        />
        <img
          class="police"
          src="/police.svg"
          alt=""
          style="
            position: absolute;
            z-index: 1;
            border-radius: 15px;
            bottom: 12%;
            width: 361px;
            right: 4%;
          "
        />
      </div>
    </div>

    <Bloque9 />
    <Bloque10 />
    <!-- <Bloque11 /> -->

    <!-- ROW 6  -->
    <div
      class="p100 pm100 pb100 row justify-content-center CuerpoTres otroCss"
      style="position: relative; background: #f2f3f9"
    >
      <img
        src="/adorno5.svg"
        alt=""
        class="adorno5 img-fluid"
        style="position: absolute; top: 0; right: -12%; top: -14%; width: 603px"
      />

      <div class="col-12 col-lg-10">
        <img
          src="/emocionada.jpg"
          class="img-fluid"
          alt=""
          style="border-radius: 18px"
        />
      </div>

      <div
        class="col-4 lg"
        data-aos="fade-right"
      >
        <img
          src="/telefono2.svg"
          alt=""
          style="
            position: absolute;
            z-index: 1;
            border-radius: 15px;
            bottom: -35%;
            width: 361px;
            left: 18%;
          "
        />
      </div>

      <div
        data-aos="fade-left"
        class="col-11 col-lg-5 pt-4"
        style="text-align: left; padding-top: 20px !important"
      >
        <!-- <img src="/line-section.png" alt=""> -->
        <div
          class="titletres"
          style="
            font-family: 'Worldwise Sans' !important;
            font-weight: bold;
            font-style: normal;
            font-size: 45px;
            font-weight: 600;
            line-height: 57px;
            overflow-wrap: initial;
            word-break: keep-all;
            color: rgb(0, 0, 0);
            width: 100%;
          "
        >
          <!-- MENSAJE DE BIENVENIDA AUTOMÁTICO, VÍA WHATSAPP -->
          {{ $t("home.p36") }}
        </div>
        <div
          class="otro-texto"
          style="
            font-size: 20px;
            font-weight: 400;
            line-height: 28px;
            color: #454745;
          "
        >
          <!-- <div class="TextSubtitle" style="font-size: 23px; margin-top: -15px">
          De llevar la experiencia de check-in de tu hotel al siguiente nivel 
        </div> -->

          <hr />
          {{ $t("home.p37") }}
        </div>
        <br />

        <div
          class="botones-final d-flex"
          v-animate-css="'zoomIn'"
          style="text-align: center; margin: auto; width: 100%"
        >
          <a
            class="btmr"
            href="https://app.wacheckin.com/register"
            style=""
          >
            <button
              class="btn"
              :style="{ background: $cg.color.colorOne }"
              style="
                color: white;
                border-radius: 10px;
                font-weight: 600;
                box-shadow: #1f962382 0px 10px 25px;
                font-size: 16px;
                line-height: 24px;
                transition: color, background-color 0.15s ease-in-out;
                padding: 16px 25px;
              "
            >
              {{ $t("home.p7") }}
            </button>
          </a>

          <button
            data-toggle="modal"
            data-target="#modalReserva"
            class="btn"
            :style="{
              'border-color': $cg.color.colorOne,
              color: $cg.color.colorOne,
            }"
            style="
              border-radius: 10px;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              transition: color, background-color 0.15s ease-in-out;
              padding: 16px 25px;
            "
          >
            {{ $t("home.p8") }}
          </button>
        </div>
      </div>
    </div>

    <Footer />

   
    <modal-reserva />
    <ModalVideoMovil />
    <ModalQR />
    <ModalWhatsapp />
    <ModalVideo />
  </div>
</template>
<script>
import modalReserva from "./modalReserva.vue";
import ModalVideoMovil from "./modalVideoMovil.vue";
import Footer from "../../components/Home/footer.vue";
import ModalQR from "./modalQR.vue";
import ModalWhatsapp from "./ModalWhatsapp.vue";
import Bloque1 from "@/components/Home/bloque1.vue";
import Bloque3 from "@/components/Home/bloque3.vue";
import Bloque9 from "@/components/Home/bloque9.vue";
import Bloque10 from "@/components/Home/bloque10.vue";
// import Bloque11 from "@/components/Home/bloque11.vue";
import ModalVideo from "./modalVideo.vue";

export default {
  mounted() {
    this.listarPropiedad();

    window.onload = function () {
      (function (C, A, L) {
        let p = function (a, ar) {
          a.q.push(ar);
        };
        let d = C.document;
        C.Cal =
          C.Cal ||
          function () {
            let cal = C.Cal;
            let ar = arguments;
            if (!cal.loaded) {
              cal.ns = {};
              cal.q = cal.q || [];
              d.head.appendChild(d.createElement("script")).src = A;
              cal.loaded = true;
            }
            if (ar[0] === L) {
              const api = function () {
                p(api, arguments);
              };
              const namespace = ar[1];
              api.q = api.q || [];
              if (typeof namespace === "string") {
                cal.ns[namespace] = cal.ns[namespace] || api;
                p(cal.ns[namespace], ar);
                p(cal, ["initNamespace", namespace]);
              } else p(cal, ar);
              return;
            }
            p(cal, ar);
          };
      })(window, "https://app.cal.com/embed/embed.js", "init");
      Cal("init", { origin: "https://cal.com" });

      Cal("ui", {
        styles: { branding: { brandColor: "#3f9c29" } },
        hideEventTypeDetails: false,
        layout: "month_view",
      });
    };
  },
  components: {
    modalReserva,
    ModalVideoMovil,
    Footer,
    ModalQR,
    ModalWhatsapp,
    Bloque1,
    Bloque3,
    Bloque9,
    Bloque10,
    // Bloque11,
    ModalVideo,
  },
  data() {
    return {
      nombre: null,
      hotel: null,
      telefono: null,
      email: null,
      terminos: false,
      loader: false,
      propiedad: null,
    };
  },
  methods: {
    listarPropiedad() {
      let url = "https://api.wacheckin.com/api/propiedad/example";
      const datos = {
        id: 10,
      };

      axios.post(url, datos).then((res) => {
        this.propiedad = res.data;
      });
    },
    EnviarSaberMas() {
      if (!this.nombre) {
        toastr.error($t("home.p39"));
        return;
      }
      if (!this.hotel) {
        toastr.error($t("home.p40"));
        return;
      }
      if (!this.telefono) {
        toastr.error($t("home.p41"));
        return;
      }
      if (!this.email) {
        toastr.error($t("home.p42"));
        return;
      }
      if (!this.terminos) {
        toastr.error($t("home.p43"));
        return;
      }

      this.loader = true;

      var url = "https://api.wacheckin.com/api/contacto/sabermas";
      // var url = 'http://127.0.0.1:8001/api/contacto/sabermas';

      const datos = {
        nombre: this.nombre,
        hotel: this.hotel,
        telefono: this.telefono,
        email: this.email,
      };

      axios
        .post(url, datos)
        .then((res) => {
          toastr.success($t("home.p43"));
          this.nombre = null;
          this.hotel = null;
          this.telefono = null;
          this.email = null;
          this.loader = false;
        })
        .catch((e) => {
          this.loader = false;
          toastr.error($t("home.p44"));
        });
    },
  },
};
</script>
<style lang="scss">
#mi-div {
  position: relative;
  /* Establece la altura y ancho del div */
  height: 300px;
  width: 100%;
}

.video-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.content {
  position: relative;
  z-index: 1;
  /* Establece la altura y ancho del contenido */
  height: 500px;
  width: 100%;
}

@media (min-width: 768px) {
  .otroCss {
    padding-bottom: 200px !important;
  }
  .btmr {
    margin-right: 20px !important;
    .btn {
    }
  }
}
@media (max-width: 768px) {
  .margi {
    margin-top: 50px !important;
  }
  .otroCss {
    padding-bottom: 0px;
  }

  .btmr {
    .btn {
      width: 100%;
    }
  }
}

.single-feature-44 {
  text-align: center;
  max-height: 349px;
  padding: 50px 40px;
  padding-bottom: 42px;
  transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
  box-shadow: 0px 30px 70px 0px rgba(0, 5, 39, 0.1);
  margin-bottom: 30px;
}

.whatsappLink:hover {
  text-decoration: none;
}

#whatsappchat {
  position: fixed;
  z-index: 999;
  bottom: 15px;
}

#whatsappchat {
  right: 15px;
  direction: ltr;
}

.whatsapptext {
  background-color: #ededed;
  padding: 8px 10px;
  border-radius: 14px;
  position: relative;
  margin-right: 5px;
  color: #000;
  vertical-align: middle;
}

.whatsappimage {
  height: 30px;
  transform: scale(0.5);
  content: url("../../../public/whatsapp.png");
}

.whatsapptext::before {
  content: "";
  position: absolute;
  border-bottom: solid 10px transparent;
  border-right: solid 10px #ededed;
  border-top: solid 10px transparent;
  left: -6px;
  top: 5px;
}

.woot-widget-bubble.woot-widget--expanded {
  z-index: 0 !important;
}
</style>
<style lang="scss" scoped>
@media (max-width: 768px) {
  .bloqueNuevo {
    text-align: center;
  }
  .centerxs {
    text-align: center;
  }
  .bloqueQR {
    text-align: center;
  }

  .botones {
    margin: auto;
    justify-content: center;
  }

  .pm100 {
    padding-top: 100px;
  }
  .botones-final {
    flex-direction: column;
  }
  img.adorno5 {
    position: absolute;
    top: -7% !important;
    right: -40% !important;
    width: 715px !important;
  }
  img.police {
    position: absolute;
    z-index: 1;
    border-radius: 15px;
    bottom: -15% !important;
    width: 361px;
    right: -4% !important;
  }
  .titletres {
    font-size: 36px !important;
  }
  .single-feature-2 {
    max-height: fit-content !important;
    margin-bottom: 25px;
  }
  .title-row2 {
    font-size: 40px !important;
    span {
      font-size: 56px !important;
    }
  }

  .dos .subtitle {
    text-align: center !important;
  }
  .contenido {
    justify-content: center !important;
    .imagen-telefono {
      height: 310px;
      border-radius: 0px 0px 30px 20px !important;
    }
  }
  p,
  .TextSubtitle {
    font-size: 17px !important;
  }
}

@media (min-width: 768px) {
  .pb100 {
    padding-bottom: 100px;
  }
  .mg300 {
    // margin-top: 300px !important;
    padding-top: 300px !important;
  }

  .p100 {
    padding-top: 100px;
  }
  .p200 {
    padding-top: 200px;
  }

  .botones-final {
    flex-direction: row;
  }
  .ultimo {
    padding-top: 86px;
  }
  .contenido {
    justify-content: center !important;
    .imagen-telefono {
      border-radius: 0px 0px 65px 44px !important;
    }
  }

  .title-row2 {
    font-size: 70px;
    span {
      font-size: 70px;
    }
  }

  .boton-registrate {
    margin-right: 20px;
  }
  .dos {
    text-align: center;
    padding-top: 61px;
    padding-left: 88px;
    padding-right: 95px;
  }
}

.contenedor-loader {
  position: absolute;
  background: #000000a3;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: 20px;
  z-index: 1;
  justify-content: center;
  align-items: center;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.footerDos {
  height: 90px !important;
  align-items: center;
  color: white;
}
.form-contacto {
  .form-group {
    width: 100%;
  }
  .form-control {
    background: transparent;
    border: none;
    border-bottom: 1px solid #3d3d3d;
    border-radius: 0;
    color: white;

    &:focus {
      box-shadow: none !important;
    }
  }
}
.otro-texto {
  margin-top: 10px;
  font-size: 20px !important;
  line-height: 30px;
  color: rgba(89, 89, 89, 1);
}

.single-feature-2 {
  min-height: 349px !important;
  max-height: 349px;
  padding: 50px 40px;
  padding-bottom: 42px;
  transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
  box-shadow: 0px 30px 70px 0px rgba(0, 5, 39, 0.1);
}

.feature-icon-2 {
  margin-bottom: 35px;
}

.feature-content-title-2 {
  font-size: 20px;
  margin-bottom: 20px;
  color: #0a1426;
}

.feature-content-desc-2 {
  font-size: 15px;
  line-height: 24px;
  color: #535559;
  margin-bottom: 35px;
}

.textos p {
  font-size: 21px;
  font-weight: 300;
}
.CuerpoCinco {
  margin-top: 200px;
  padding-bottom: 73px;
}
.CuerpoCuatro {
  margin-top: 572px;
  padding-bottom: 73px;
}
.CuerpoTres {
  padding-bottom: 43px;
  .otro-texto {
    margin-top: 10px;
    font-size: 20px;
    line-height: 30px;
    color: rgba(89, 89, 89, 1);
  }
}

.CuerpoDos {
  margin-top: 101px;
  padding-bottom: 73px;
  .otro-texto {
    margin-top: 10px;
    font-size: 20px;
    line-height: 30px;
    color: rgba(89, 89, 89, 1);
  }
}
.Content {
  // background: #F5F6F6;
}
.uno {
  padding-top: 74px;

  background-repeat: no-repeat;
  background-size: contain;

  .adorno {
  }
}
.dos {
  //   padding-right: 163px;

  .title {
    display: none;
    font-size: 59px;
    letter-spacing: -4px;
    line-height: 85px;
    margin-bottom: 0;
    text-align: left;
    -webkit-background-clip: text;
    color: #eece22;
    display: inline-block;
    font-weight: 600;
    /* letter-spacing: -2px; */
    /* line-height: 52px; */
    padding: 0 0 8px;
    /* text-align: left; */
    word-break: break-word;
  }

  .subtitle {
    color: #333;
    text-transform: uppercase;
    display: inline-block;
    font-size: 60px;
    font-weight: 900;
    line-height: 52px;
    padding: 0 0 8px;
    text-align: left;
    word-break: break-word;
    font-size: 71px;
    line-height: 85px;
    margin-bottom: 0;
    text-align: left;
  }

  .subtitleDos {
    font-size: 28px;
    /* letter-spacing: -4px; */
    margin-bottom: 0;
    text-align: left;
    -webkit-background-clip: text;
    color: rgb(21, 21, 21);
    display: inline-block;
    font-weight: 450;
    letter-spacing: -2px;
    line-height: 35px;
    padding: 0 0 8px;
    /* text-align: left; */
    word-break: break-word;
  }

  .otro-texto {
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    word-break: keep-all;
  }
}
</style>
