export const home = {
    p1: 'Auto check-in',
    p2: 'Auto check-in',
    p3: 'Para hoteles y apartamentos turísticos',
    p4: 'Facilita a tus huéspedes el check-in mediante escaneo de QR  o link por WhatsApp ',
    p5: "Facilita a tus huéspedes el check-in mediante escaneo de QR  o link por WhatsApp  ",
    // p5_5:`de tu alojamiento evitando colas e impresión de papel contribuyendo a un mundo más sostenible y verde.`,
    p6: "Prueba el plan PREMIUM gratis por 14 días",
    p7: 'Empieza ahora!',
    p8: 'Reserva una demo',
    p9: "Facilita a tus huéspedes el check-in mediante escaneo de QR  o link por WhatsApp ",
    p10: "Rápido y fácil.",
    p11: "Para todo tipo de Alojamientos Turísticos",
    p12: "Descubre cómo nos adaptamos a ti",
    p13: "",
    p14: "No habrá colas ni esperas innecesarias en el mostrador de recepción.",
    p15: "Tus huéspedes podrán completar el proceso de check-in directamente desde sus dispositivos móviles, en cualquier momento y lugar que les resulte conveniente o en el mismo hotel escaneando el QR.",
    p16: `¿CÓMO FUNCIONA`,
    p17: `QRCHECKIN?`,
    p18: `Nuestro proceso de check-in online es rápido, sencillo y sin complicaciones`,
    p19: `Consta de tres simples pasos que permitirán que tus huéspedes realicen su
    registro de manera eficiente`,
    p20: `¡Aquí te explicamos cómo funciona!`,
    p21: `Escanea QR o link por WhatsApp`,
    p22: `Una vez tus huéspedes lleguen al hotel, encontrarán un código QR o
    recibirán un enlace directo a nuestra aplicación web de check-in
    online.`,
    p23: `Escanea Documento (DNI/Pasaporte)`,
    p24: `Solicitaremos que escaneen su documento de identidad
    válido, ya sea pasaporte o documento de identidad nacional.`,
    p25: `Firma las políticas del alojamiento`,
    p26: `Una vez escaneado el documento, llegarán la etapa final del proceso de
    check-in. Aquí podrán firmar electrónicamente utilizando el mismo
    sistema.`,
    p27: ` Mensaje de bienvenida automático, via WhatsApp`,
    p28: `Los huéspedes recibirán automáticamente un mensaje de bienvenida personalizado,
    Tú tienes la oportunidad de agregar un toque especial, personalizando el mensaje
    para cada uno de ellos.`,
    p29: `Crea tu cuenta`,
    p30: `Ofrece servicios y experiencias al WhatsApp de tu huésped`,
    p31: `Ofrece a tus huéspedes experiencias personalizadas, servicios propios o
    externos, transporte, actividades de ocio… ¡Y genera más dinero de cada reserva
    y más reservas directas!`,
    p33: `Envío de partes automático a la policía`,
    p34: `Para tu tranquilidad y cumpliendo con la legislación, nuestro sistema envía todas las noches los partes de viajeros alojados a las autoridades correspondientes`,
    p35: `Reducirás significativamente el tiempo empleado por tu personal de
    recepción.`,
    p36: `NO PIERDAS LA OPORTUNIDAD`,
    p37: `Implementa nuestro sistema de check-in online y brinda a tus huéspedes una
    bienvenida sin complicaciones y un servicio excepcional desde el primer momento
    de su llegada.`,
    p38: `Términos y condiciones`,
    p39: `El nombre es requerido`,
    p40: `El nombre del hotel es requerido`,
    p41: `Teléfono es requerido`,
    p42: `El Email es requerido`,
    p43: `Debe aceptar las políticas de privacidad`,
    p44: `Error al procesar la solicitud"`,
    p45: `Enviando mensaje..."`,
    p46: `Nombre completo`,
    p47: `Correo electrónico`,
    p48: `Teléfono`,
    p49: `Escríbenos qué necesitas`,
    p50: `Mensaje enviado correctamente`,
    p51: `El equipo de ventas se pondrá en contacto lo mas rápido posible`,
    p52: `Cerrar`,
    p53: `Contactar`,
    p54: `El asunto  es requerido`,
    p55: `Error al procesar la solicitud`,
    p56: `Apartamentos`,
    p57: `Villas`,
    p58: `Hoteles`,
    p59: `Campings`,
    p60:`Envía alertas a tus huéspedes en horas concretas por WhatsApp`,
    p61:`Nuestro sistema de alertas te permite enviar mensajes personalizados a tus
    huéspedes en horas específicas a través de WhatsApp.`,
    p62:`Puedes programar alertas para recordarles sobre eventos, tours disponibles a
    primera hora de la mañana o simplemente enviarle la carta del restaurante una
    hora antes del almuerzo 😉`,
    p63:`Hola, ¿cómo puedo ayudarte?`,


    // Correciones generales 
    p64:`el check-in`,
    p65:`de tus huéspedes`,
    p66:`Escáner de DNI - Pasaporte`,
    p67:`Firma digital`,
    p68:`Envío a la policía`,
    p69:`Verificación de identidad`,
    p70:`Ventas cruzadas`,
    p71:`Valoraciones automáticas`,
    p72:`Pruébalo Gratis`,
    p73:`Agiliza`,
    p74:`Automatiza`,
    p75:`Ahorra`,

    p76:`TE INTEGRAMOS CON MÁS DE 50 GESTORES DE RESERVAS (PMS) INTEGRADOS`,

    p77:`¡Los datos hablan por nosotros!`,
    p78:`Propiedades que nos usan`,
    p79:`Check-ins completados`,
    p80:`Países implantados`,


    p81:`Verificación de identidad`,
    p82:`Comprobamos la identidad de tus huéspedes mediante nuestro software de
    comparación biométrica, asegurando que su documentación corresponde con la
    persona que accede a tu vivienda.`,

    p83:`Pagos Online`,
    p84:`Solicita los pagos online relacionados con la reserva, ofertas y experiencias,
    tasas turísticas y todos los pagos personalizados que quieras incluir durante el
    check-in online o durante su estancia.`,


    p85:`CONTACTA CON QRCHECKIN`,
    p86:`Políticas de privacidad(Huésped)`,
    p87:`Políticas de privacidad(Alojamiento)`,

    p88:`¡Check-in ecológico y sin complicaciones! Tu llegada fácil y sin papel, cuidando
        juntos nuestro planeta.`,
    p89:`¡Check-in ecológico y sin complicaciones! cuidando juntos nuestro planeta.`,



    p90:`Procesos de demostración`,
    p91:`Check-in por QR`,
    p92:`Escanea el QR del alojamiento`,
    p93:`Check-in por WhatsApp`,
    p94:`QR DEMO`,
    p95:`Puedes Escanear el QR para realizar tu check-in demo`,
    p96:`Habilitar Verificación de identidad`,
    p97:`Habilitado`,
    p98:`Inhabilitado`,
    p99:`Código de seguridad`,
    p100:`Con este QR pueden iniciar un proceso de check-in demostrativo en un
    alojamiento.`,
    p101:`Acceder`,

    p102:`Enviando link demo`,
    p103:`Por favor espere ....`,
    p104:`Link demo enviado con éxito`,
    p105:`Le llegara un mensaje a su WhatsApp para iniciar el proceso de check-in`,
    p106:`PROCESO DEMOSTRACIÓN`,
    p107:`Escribe tus datos para recibir en tu WhatsApp nuestro proceso demo de checkin.`,
    p108:`Habilitar Verificación de identidad`,
    p109:`Nombre`,
    p110:`Correo electrónico`,
    p111:`Teléfono`,
    p112:`Enviar Link `,
    p113:`Estamos integrados con SES.HOSPEDAJES, la nueva plataforma para registro de viajeros operada por el Ministerio del Interior`,
    p114:`Hola, me gustaría recibir más información sobre WACheckin (Ventas).`,
    p115:`Estamos integrados con SES.HOSPEDAJES, la plataforma de registro del Ministerio del Interior.`,
    p116:`Así funciona`,
    p117:`Ver video`,
    p118:``,
    p119:``,
    p120:``,
    p121:``,

}